@font-face {
  font-family: 'Aeonik';
  font-weight: 900;
  src: url(./fonts/Aeonik-Bold.otf);
}
@font-face {
  font-family: 'Aeonik';
  font-weight: 300;
  src: url(./fonts/Aeonik-Light.otf);
}
@font-face {
  font-family: 'Aeonik';
  font-weight: 600;
  src: url(./fonts/Aeonik-Medium.otf);
}

body {
  background: black;
  font-family: "Aeonik";
}

#header{
  position: relative;
  width: 100%;
}

.app {
  padding: 5px;
  color: white;
  width: 980px;
  margin: 0 auto;
}
#content{
  padding:0 15px;
}

.play{
  background-color: #000000aa;
  background-image: url(../public/play.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10%;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  cursor:pointer;
  transition: opacity 0.1s;
}
.play.vidToggle{  
  cursor:pointer;
  display: none;
}

#btn{
  color:white;
  padding:15px 60px;
  font-size: 1.1em;
  border:1px solid white;
  background-color: transparent;
  transition: all 0.25s;
  margin-top: 10px;
}
#btn.toggled{
  height: 0;
  overflow: hidden;
  padding: 0;
  border:0;
}
#toggle{
  height: 0;
  overflow: hidden;
  transition: all 0.25s;
  display: none;
}
#toggle.toggled{
  height: 100%;
  display: block;
}

h4,h5{
  margin: 0;  
  font-weight: 600;
}

h5{
  color:silver;
  font-weight: 200;
}

h1{
  font-size: 1.6em;
}

.arrow{
  transform: rotate(90deg) translate(10px,-5px);
}

#iframe{
  width:100%;
  border:0;
  height: 675px;
}
iframe .UIBox__Box-sc-1nd8pke-0.gvCPNI.private-flex__child{
  display: none!important;
}

.separator{
  width: calc(100% - 10px);
  height: 1px;
  background:#ffffff1a;
  display: block;
  margin: 0 auto;
}
#ty .separator{
  background:#d7d7d76e;
  margin:35px auto;
}
#ty #header{
  width: 980px;  
  margin:0 auto;
  margin-top:5px;
}
#ty{
  text-align: center;
  height: 100%;
  width: 100%;
  position: absolute;
  padding: 0;
}
#ty .top{  
  left:50%;
  transform: translateX(-50%);
  position: relative;
  width: fit-content;
  margin-top:40px;
  padding-bottom: 50px;
}
#ty h1,#ty h2,#ty h3{
  margin: 0;
}
#ty h1{
  margin-bottom: 20px;
}

#ty span{
  margin-left:20px;
}
#ty .cat{
  display: flex;
  align-items: center;
  margin:3px 0;
}
#ty h1{
  font-size: 3.5em;
  font-weight: 300;
}
#ty .confirm{
  position: relative;
}
#ty .big{
  font-size: 5em;
  color:#ffffff;
}
#ty .big.check{
  font-size: 3em;
  color:#54d868;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top:22px;
}

@media only screen and (max-width: 980px) {
  .app {
    width: calc(100% - 10px);
  }
  #ty #header{
    width: calc(100% - 10px);
  }
}